import React from 'react'
import './Home.css'
import {ExternalLinks} from "./ExternalLinks"
import CoursesInfo from "./CoursesInfo"
import {YoutubePlayLists} from "./YoutubePlayLists"
/*
const SectionTitle = (props: {imgUrl: string, title: string}) => (
    <div className="row">
        <div className="col-md-4 col-12 py-2 pl-4">
            <img src={props.imgUrl} width="100%" alt="cpp"/>
        </div>
        <div className="col-md-8 col-12">
            <div className="series">{props.title}</div>
        </div>
    </div>
)
*/

const CourseEntry = (course: any, index: any) => {
    const hasDate = (course.date && course.date.length > 0)
    const link = <a rel="noopener noreferrer" target="_blank" href={"https://docs.google.com/forms/d/e/1FAIpQLSfBEFBcRQsq7rA1X12x12y3LQnNc4iCh3_gyoEd56Nwor72Gw/viewform"}>我有興趣</a>
    return (
        <div key={"course_entry_" + index} className={"row py-2" + (index % 2 === 0 ? " even" : "")}>
            <div className="col-lg-5 col-md-5 col-7 border-right">
                <a style={{fontSize: "1.1em"}} href={course.url} target="_blank" rel="noopener noreferrer">{course.title}</a>
            </div>
            <div className="course_date col-lg-2 col-md-3 col-5" style={{lineHeight: "1.6em"}}>
                {hasDate ? (course.enrolling ? <a className="font-weight-bold" rel="noopener noreferrer"  href={course.url} target="_blank">{course.date[0]}</a> : course.date[0]) : ""}
            </div>
            <div className="col-12 d-md-none d-flex" style={{padding: "2px"}}>
            </div>
            <div className="col-lg-3 d-lg-flex col-7 d-md-none border-left" style={{fontSize: "0.95em"}}>
                {course.hours}
            </div>
            <div className="col-lg-3 d-lg-none d-md-flex d-none col-md-2 col-7 border-left">
                {course.hours}
            </div>
            <div className="col-lg-2 col-md-2 col-5 border-left">
                {course.enrolling ? <a className="font-weight-bold" rel="noopener noreferrer"  href={course.url} target="_blank">招生中</a> : (course.underSurvey ? link : (course.repeated ? <span style={{fontSize: "0.8em"}}>{ course.repeated }</span> : ""))}
            </div>
        </div>
    )
}
const ContentEntry = (
    courses: any) => (
    <div className="row mx-1">
        <div className="col-12">
            {courses.map((e: any, i: number) => CourseEntry(e, i))}
        </div>
    </div>
)


const SimpleSection = (
    props: {
            children: any
    }) => (
    <div className="card border-dark mb-3">
            <div className="row mx-1 font-weight-bold py-2 d-md-flex d-none">
                    <div className="col-lg-12 col-md-12">
                            <div className="row d-md-flex d-none">
                                    <div className="col-lg-5 col-md-5 border-right">課程名稱</div>
                                    <div className="col-lg-2 col-md-3 col-6">預計開課日期</div>
                                    <div className="col-lg-3 col-md-2 col-3 border-left">課程影片總長度</div>
                                    <div className="col-lg-2 col-md-2 col-3 border-left"></div>
                            </div>
                    </div>
            </div>
            {props.children}
    </div>
)
/*
const Section = (
    props: {
            imgUrl: string,
            title: string,
            children: any
    }) => (
    <div className="card border-dark mb-3">
            <SectionTitle imgUrl={props.imgUrl} title={props.title}/>
            <div className="row mx-1 font-weight-bold py-2 d-md-flex d-none">
                    <div className="col-lg-12 col-md-12">
                            <div className="row d-md-flex d-none">
                                    <div className="col-lg-5 col-md-5 border-right">課程名稱</div>
                                    <div className="col-lg-2 col-md-3 col-6">預計開課日期</div>
                                    <div className="col-lg-3 col-md-2 col-3 border-left">課程影片總長度</div>
                                    <div className="col-lg-2 col-md-2 col-3 border-left"></div>
                            </div>
                    </div>
            </div>
            {props.children}
    </div>
)
*/

const Home = () => (
    <div className="container p-0">
        <img className="img-fluid d-block mt-3 border mx-auto" src="logo.png" alt="logo"/>
        <ExternalLinks />
        <div className="card border-dark mb-3">
            <div className="card-header">
                關於我
            </div>
            <div className="row pl-3">
                <div className="col-md-4 col-12 text-center pt-4 my-auto">
                    <div className="row">
                        <div className="col">
                            <img src="me.jpg" width="220" className="border" alt="me"/>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col">
                            李根逸 博士<br/>Dr. Ken-Yi Feis Lee
                        </div>
                    </div>
                </div>
                <div className="col-md-8 col-12 pr-5 pl-0">
                    <ul className="list-group list-group-flush">
                    

                        <li className="list-group-item" style={{fontSize: "1.1em"}}>
                            <a href="https://management.ntu.edu.tw/IM/faculty/teacher/sn/419"
                               target="_blank"
                               rel="noopener noreferrer">國立臺灣大學資訊管理學系</a>兼任助理教授<br/><span style={{fontSize: "0.8em"}}>(教授 「資料結構與進階程式設計 (IM1010)」與「遊戲設計 (IM5023)」課程)</span>
                        </li>

                        <li className="list-group-item" style={{fontSize: "1.1em"}}>
                            <a href="https://train.csie.ntu.edu.tw/train/teacher.php?id=4"
                                target="_blank"
                                rel="noopener noreferrer">國立臺灣大學資訊系統訓練班</a>資深講師<br/><span style={{fontSize: "0.8em"}}>(因興趣長期從事程式推廣教育，已有十五年、三千小時的現場課堂教學經驗)</span>
                        </li>

                        <li className="list-group-item" style={{fontSize: "1.1em"}}>
                            <a href="http://www.sigono.com/"
                               target="_blank"
                               rel="noopener noreferrer">信革數位 (SIGONO)</a> 技術總監<br/><span style={{fontSize: "0.8em"}}>(負責架構設計並帶領技術團隊)</span>
                        </li>

                        <li className="list-group-item" style={{padding: "10px"}}>學經歷</li>
                        
                        <li className="list-group-item" style={{fontSize: "1.1em"}}>
                            <a href="https://www.csie.ntu.edu.tw/"
                                                           target="_blank"
                               rel="noopener noreferrer">台灣大學資訊工程所</a>博士<br/><span style={{fontSize: "0.8em"}}>(專長領域為電腦視覺、電腦圖學、影像處理與機器學習) [<a href="https://scholar.google.com/citations?user=zTU8IJIAAAAJ&hl=en"
                                                                                                                                                                               target="_blank"
                                                                                                                                                                               rel="noopener noreferrer">Google Scholar</a>]</span>
                        </li>
                        <li className="list-group-item" style={{fontSize: "1.1em"}}><a
                            href="https://www.rayark.com/"
                            target="_blank"
                            rel="noopener noreferrer">雷亞遊戲 (Rayark) </a>技術總監<br/>
                            <span style={{fontSize: "0.8em"}}>(負責架構設計並帶領小型技術與機器學習團隊)</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="p-2">
            <img className="rounded" width="100%" src="images/flow.png" alt={"C++ 從入門到專家課程地圖"}/>
        </div>
        <SimpleSection>
            {ContentEntry(CoursesInfo.CppBasics)}
        </SimpleSection>
        <div className="card border-dark mb-3">
            <div className="card-header">
                過往演講分享投影片
            </div>
            <div className="row">
                <div className="col-lg-6 text-center">
                    <div className="card border-dark m-3">
                        <div className="card-header">分享於台北遊戲開發者論壇<br/>(TGDF 2023)</div>
                        <div className="card-body">
                            <a href="https://docs.google.com/presentation/d/e/2PACX-1vSZnmYFydQJnCjRbE1n1akSEcPeBOCv6pW4ZyvAGjy3G8w39sH77Ac8a4Pyu7288tY6XYzcr02ny1nc/pub?slide=id.p"
                               target="_blank"
                               rel="noopener noreferrer"><img src="tgdf_2023.png" width="100%" alt="talk"/></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 text-center">
                    <div className="card border-dark m-3">
                        <div className="card-header">分享於台北 Tensorflow 使用者社群<br/>(TFUG Taipei)</div>
                        <div className="card-body">
                            <a href="https://docs.google.com/presentation/d/e/2PACX-1vT-RHZD7MJN6izs2Grbk4yFlw6rKdxGJUQaNi0sBP2HK6Vs29iBgsCzdrIYvyAdYLMCuhToUre8eUEF/pub?slide=id.p"
                               target="_blank"
                               rel="noopener noreferrer"><img src="talk_4.png" width="100%" alt="talk"/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 text-center">
                    <div className="card border-dark m-3">
                        <div className="card-header">分享於開源人年會<br/>(COSCUP 2019)</div>
                        <div className="card-body">
                            <a href="https://docs.google.com/presentation/d/e/2PACX-1vSOpX-sZT_54NdnngdtaJUwnQjoVvju45oS-sIGHcnzhX_LTMb7KHVa7lCJIrx2qZewdA43pI8jALgl/pub"
                               target="_blank"
                               rel="noopener noreferrer"><img src="talk_3.png" width="100%" alt="talk"/></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 text-center">
                    <div className="card border-dark m-3">
                        <div className="card-header">分享於台北遊戲開發者論壇<br/>(TGDF 2019)</div>
                        <div className="card-body">
                            <a href="https://docs.google.com/presentation/d/e/2PACX-1vQk8RfZxqA4KlRdO_EKyL7x7Fk3asRU4RhMS53WSGzjWxBkq5T0yfxzloCJ7_5oO-CBYzfca4_aQy3q/pub"
                               target="_blank"
                               rel="noopener noreferrer"><img src="talk_2.png" alt="talk" width="100%"/></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 text-center">
                    <div className="card border-dark m-3">
                        <div className="card-header">分享於成功大學資訊工程系</div>
                        <div className="card-body">
                            <a href="https://docs.google.com/presentation/d/e/2PACX-1vQ17_XtJHkJnASHbmmuDgUr0hr6bN5c7A6VHMCZTz1PTE8Rkv9R-0qMeTImoyZEfeYAr-T7GXyhLhGD/pub"
                               target="_blank"
                               rel="noopener noreferrer"><img src="talk_1.png" alt="talk" width="100%"/></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 text-center">
                    <div className="card border-dark m-3">
                        <div className="card-header">分享於中央創遊社</div>
                        <div className="card-body">
                            <a href="https://docs.google.com/presentation/d/e/2PACX-1vRN4bzcR5D_sLwId4GvVQNhEgjvgpDTwLh7sF68-4KYFZTljbvuUqPNbBVuF6dQxF0YDAFlMPxuTmsU/pub"
                               target="_blank"
                               rel="noopener noreferrer"><img src="unity_1.png" alt="talk" width="100%"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <YoutubePlayLists />
        <footer className="page-footer font-small blue">
            <div className="footer-copyright text-center py-3">© 2024 Copyright <a href="http://feis.studio" target="_blank" rel="noopener noreferrer" className="mx-1">Feis Studio</a> (<a href="mailto:feis.studio@gmail.com"
                                                                                                                                                                                            target="_blank" rel="noopener noreferrer">feis.studio@gmail.com</a>)
            </div>
        </footer>
    </div>
)

export default Home